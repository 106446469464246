import * as d3 from "d3";

export const consumeProps = (id: string): CrateResponse => {
  const dataInputEl = d3.select(`#${id}`);
  const mainData = JSON.parse(dataInputEl.attr("value")) as CrateResponse;

  dataInputEl.remove();

  return mainData;
};
